import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import RealDashboard from "../realDashboard";
export default function Dashboard() {
  const { logout } = useAuth();
  const [dbName, setdbName] = useState("sira");
  const [tab, setTab] = useState(0);
  const history = useHistory();

  async function handleLogout() {
    try {
      await logout();
      history.push("/login");
    } catch {
      console.log("Failed to log out");
    }
  }

  return (
    <div>
      <div className="w-100 text-center mt-2">
        <Button
          onClick={() => {
            setTab(0);
          }}
        >
          back
        </Button>
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
      {tab === 0 ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100vw",
              height: "90vh",
            }}
          >
            <div className="card w-80 m-1">
              <div className="card-body">
                <h2 className="card-title">Sira</h2>
                <button
                  onClick={() => {
                    setdbName("sira");
                    setTab(1);
                  }}
                  className="btn btn-primary"
                >
                  Admin
                </button>
              </div>
            </div>
            <div className="card w-80 m-1">
              <div className="card-body">
                <h2 className="card-title">Nsi</h2>
                <button
                  onClick={() => {
                    setdbName("nsi");
                    setTab(1);
                  }}
                  className="btn btn-primary"
                >
                  Admin
                </button>
              </div>
            </div>
            <div className="card w-80 m-1">
              <div className="card-body">
                <h2 className="card-title">Notification</h2>
                <Link to="/notification">
                  <button className="btn btn-primary">Send</button>
                </Link>
              </div>
            </div>
          </div>
        </>
      ) : (
        <RealDashboard dbName={dbName} />
      )}
    </div>
  );
}
