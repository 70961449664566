import firebase from "firebase";
import React, { useState } from "react";

const AddContact = ({ dbName, subdbName, type }) => {
  const [department, setDepartment] = useState("");
  const [contact, setContact] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    firebase
      .database()
      .ref(`/app/imp_contact/`)
      .push({
        department,
        contact
      });
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="col g-3 ">
        <div className="col-auto">
          Contact:
          <label  className="visually-hidden">
            Department:
          </label>
          <input
            type="text"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            className="form-control"
            id="staticEmail2"
            placeholder="eg: Police"
          />
        </div>
        <div className="col-auto">
        Contact No.:
          <input
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            type="text"
            className="form-control m-1"
            name="option1"
          />
        </div>
        <div className="col-auto">
          <button type="submit" className="btn btn-primary mb-3">
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddContact;
