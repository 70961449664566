import { CircularProgress } from "@mui/material";
import firebase from "firebase";
import React, { useEffect, useState } from "react";

const EditQuizItem = ({ EditingItemURL, setShowEdit }) => {
  const [Loading, setLoading] = useState(true);
  const [Question, setQuestion] = useState("");
  const [Option1, setOption1] = useState("");
  const [Option2, setOption2] = useState("");
  const [Option3, setOption3] = useState("");
  const [Option4, setOption4] = useState("");
  const [CorrectOption, setCorrectOption] = useState("");
  const getData = () => {
    setLoading(true);
    firebase
      .database()
      .ref(EditingItemURL)
      .on("value", function (snapshot) {
        setQuestion(snapshot.val().question);
        setOption1(snapshot.val().options[0]);
        setOption2(snapshot.val().options[1]);
        setOption3(snapshot.val().options[2]);
        setOption4(snapshot.val().options[3]);
        setCorrectOption(snapshot.val().correct_option);
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
      // eslint-disable-next-line
  }, [EditingItemURL]);

  const handleSubmit = (e) => {
    e.preventDefault();
    firebase
      .database()
      .ref(EditingItemURL)
      .set({
        question: Question,
        options: [Option1, Option2, Option3, Option4],
        correct_option: CorrectOption,
      });
    setShowEdit(false);
  };
  return (
    <div>
      <div
        className="modal fade show"
        id="exampleModalLive"
        tabIndex="-1"
        aria-labelledby="exampleModalLiveLabel"
        style={{ display: "block" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLiveLabel">
                Edit
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowEdit(false)}
              ></button>
            </div>
            <div className="modal-body">
              {Loading ? (
                <CircularProgress />
              ) : (
                <form className="col g-3 ">
                  <div className="col-auto">
                    Question:
                    <label className="visually-hidden">Question:</label>
                    <input
                      type="text"
                      value={Question}
                      onChange={(e) => setQuestion(e.target.value)}
                      className="form-control"
                      id="staticEmail2"
                      placeholder="eg: What is the capital of Nepal?"
                    />
                  </div>
                  <div className="col-auto">
                    Options:
                    <input
                      value={Option1}
                      onChange={(e) => setOption1(e.target.value)}
                      type="text"
                      className="form-control m-1"
                      name="option1"
                      placeholder="Option 1"
                    />
                    <input
                      type="text"
                      value={Option2}
                      onChange={(e) => setOption2(e.target.value)}
                      className="form-control m-1"
                      name="option2"
                      placeholder="Option 2"
                    />
                    <input
                      type="text"
                      value={Option3}
                      onChange={(e) => setOption3(e.target.value)}
                      className="form-control m-1"
                      name="option3"
                      placeholder="Option 3"
                    />
                    <input
                      type="text"
                      value={Option4}
                      onChange={(e) => setOption4(e.target.value)}
                      className="form-control m-1"
                      name="option4"
                      placeholder="Option 4"
                    />
                  </div>
                  <div className="col-auto">
                    Correct Option:
                    <label
                      htmlhtmlFor="staticEmail2"
                      className="visually-hidden"
                    >
                      Correct Option:
                    </label>
                    <input
                      value={CorrectOption}
                      onChange={(e) => setCorrectOption(e.target.value)}
                      type="text"
                      className="form-control"
                    />
                  </div>
                </form>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setShowEdit(false)}
              >
                Close
              </button>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-primary"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditQuizItem;
