import firebase from "firebase/app";
import "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyB_yQYaydhy6pooj6vGANBGH6JW1jDdZFo",
  authDomain: "sirapsbd.firebaseapp.com",
  databaseURL: "https://sirapsbd-default-rtdb.firebaseio.com",
  projectId: "sirapsbd",
  storageBucket: "sirapsbd.appspot.com",
  messagingSenderId: "127202277875",
  appId: "1:127202277875:web:9737ec721e2f146ea07a86",
  measurementId: "G-M9V6DJN72N"
});

export const auth = app.auth();
export default app;
