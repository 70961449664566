import React, { useEffect, useState } from "react";
import { DefaultEditor } from "react-simple-wysiwyg";
import { useParams } from "react-router-dom";
import firebase from "firebase";
import { Button } from "react-bootstrap";
const About = () => {
  const { dbName } = useParams();
  const [html, setHtml] = useState("Loading...");
  function onChange(e) {
    setHtml(e.target.value);
  }

  const getData = () => {
    firebase
      .database()
      .ref(`/app/about/${dbName}/`)
      .on("value", function (snapshot) {
        setHtml(snapshot.val());
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [dbName]);

  const handleSubmit = () => {
    firebase
      .database()
      .ref(`/app/about/${dbName}/`)
      .set(html)
      .then(() => {
        alert("Successfully Updated");
      });
  };

  return (
    <>
      <h1>Edit About of {dbName}</h1>
      <DefaultEditor value={html} onChange={onChange} />
      <Button onClick={handleSubmit}>Save</Button>
    </>
  );
};

export default About;
