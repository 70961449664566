import { CircularProgress } from "@mui/material";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddPhysical from "./AddPhysical";
import Delete from "../Delete";
import EditPhysical from "./EditPhysical";
import { Button } from "react-bootstrap";
const Physical = () => {
  const { dbName } = useParams();
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [ShowEdit, setShowEdit] = useState(false);
  const [EditingItemURL, setEditingItemURL] = useState("");
  const getData = () => {
    setLoading(true);
    firebase
      .database()
      .ref(`/app/physical/` + dbName)
      .on("value", function (snapshot) {
        setData(snapshot.val());
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {Loading ? (
        <CircularProgress />
      ) : (
        <>
          <h2>Edit Physical {dbName}</h2>
          <div className="row ml-2"></div>
          <AddPhysical dbName={dbName} />
          {ShowEdit && (
            <EditPhysical
              setShowEdit={setShowEdit}
              EditingItemURL={EditingItemURL}
            />
          )}
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Title</th>
                <th scope="col">Content</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(Data).map((index, id) => {
                return (
                  <tr key={index}>
                    <th scope="row">{id + 1}</th>
                    <td>{Data[index].title}</td>
                    <td>{Data[index].content.substr(0, 80)}</td>
                    <td>
                      <Button
                        onClick={async () => {
                          await setEditingItemURL(
                            `/app/physical/${dbName}/${index}`
                          );
                          await setShowEdit(true);
                        }}
                      >
                        Edit
                      </Button>
                      &nbsp;
                      {Object.values(Data).length > 1 && (
                        <Delete
                          dbUrl={`/app/physical/${dbName}/${index}`}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default Physical;
