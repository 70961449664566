import firebase from "firebase";
import React, { useState } from "react";

const AddNotification = () => {
  const [title, settitle] = useState("");
  const [message, setmessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    firebase.database().ref(`/app/notification/`).push({
      title,
      message,
    });
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="col g-3 ">
        <div className="col-auto">
          Title:
          <label className="visually-hidden">title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => settitle(e.target.value)}
            className="form-control"
            id="staticEmail2"
            placeholder="eg: Police"
          />
        </div>
        <div className="col-auto">
          Message
          <input
            value={message}
            onChange={(e) => setmessage(e.target.value)}
            type="text"
            className="form-control m-1"
            name="option1"
          />
        </div>
        <div className="col-auto">
          <button type="submit" className="btn btn-primary mb-3">
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNotification;
