import React from "react";
// import firebase from "firebase";

const index = () => {
  // const addData = () => {
  //   let data =
  //     require("./data.json").SETS["e0f915a4-9b98-4603-b958-4954f5c2d637"];
  //   data = Object.values(data);
  //   console.log(data);
  //   data.forEach((item) => {
  //       console.log({
  //           correct_option: item.correctAns,
  //           question: item.question,
  //           options: [item.optionA, item.optionB, item.optionC, item.optionD],
  //       });

  //   //   firebase.database().ref("/quiz/nsi/normal/jjshdh4uag3/Question").push({

  //   //   });
  //   });
  // };

  // useEffect(() => {
  //   addData();
  // }, []);

  return (
    <div>
      <h1>Data Adder</h1>
    </div>
  );
};

export default index;
