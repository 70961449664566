import firebase from "firebase";
import React, { useState } from "react";
import { DefaultEditor } from "react-simple-wysiwyg";

const AddPhysical = ({ dbName, subdbName, type }) => {
  const [title, settitle] = useState("");
  const [content, setcontent] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    firebase.database().ref(`/app/physical/`+ dbName).push({
      title,
      content,
    });
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="col g-3 ">
        <div className="col-auto">
          Physical:
          <label className="visually-hidden">title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => settitle(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-auto">
          Content
          <DefaultEditor
            value={content}
            onChange={(e) => {
              setcontent(e.target.value);
            }}
          />
        </div>
        <div className="col-auto">
          <button type="submit" className="btn btn-primary mb-3">
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddPhysical;
