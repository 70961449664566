import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Delete from "../Delete";
import AddQuizset from "./AddQuizset";
const Quiz = () => {
  const [Type, setType] = useState({
    display: "Normal Questions",
    dbName: "normal",
  });
  const { dbName } = useParams();
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);

  const getData = () => {
    setLoading(true);
    firebase
      .database()
      .ref(`/quiz/${dbName}/${Type.dbName}`)
      .on("value", function (snapshot) {
        setData(snapshot.val());
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [dbName, Type]);


  return (
    <div>
      <div className="p-2">
        {/* <Button
          onClick={() => {
            setType({ display: "Normal Questions", dbName: "normal" });
          }}
        >
          Normal Questions
        </Button>
        &nbsp;
        <Button
          onClick={() => {
            setType({ display: "True or False", dbName: "truefalse" });
          }}
        >
          True/false Questions
        </Button> */}
      </div>
      <h2>{Type.display}</h2>

      {Loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <>
        <AddQuizset dbName={dbName} type={Type.dbName} />
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Question set</th>
              <th scope="col">Total Question</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(Data).map((index, id) => {
              return (
                <tr key={index}>
                  <th scope="row">{id + 1}</th>
                  <td>{Data[index].title}</td>
                  <td>{Object.values(Data[index].Question).length}</td>
                  <td>
                    <Link to={`/quiz/edit/${dbName}/${Type.dbName}/${index}`}>
                      <Button>Edit</Button>
                    </Link>
                    &nbsp;
                    {Object.values(Data).length > 1 && (
                    <Delete dbUrl={`/quiz/${dbName}/${Type.dbName.toString()}/${index}`} />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        </>
      )}
    </div>
  );
};

export default Quiz;
