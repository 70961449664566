import { CircularProgress } from "@mui/material";
import firebase from "firebase";
import React, { useEffect, useState } from "react";

const EditContact = ({ EditingItemURL, setShowEdit }) => {
  const [Loading, setLoading] = useState(true);
  const [department, setdepartment] = useState("");
  const [contact, setcontact] = useState("");

  const getData = () => {
    setLoading(true);
    firebase
      .database()
      .ref(EditingItemURL)
      .on("value", function (snapshot) {
        setdepartment(snapshot.val().department);
        setcontact(snapshot.val().contact);
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [EditingItemURL]);

  const handleSubmit = (e) => {
    e.preventDefault();
    firebase.database().ref(EditingItemURL).set({
      department,
      contact,
    });
    setShowEdit(false);
  };
  return (
    <div>
      <div
        className="modal fade show"
        id="exampleModalLive"
        tabIndex="-1"
        aria-labelledby="exampleModalLiveLabel"
        style={{ display: "block" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLiveLabel">
                Edit
              </h5>
              <button
                onClick={() => setShowEdit(false)}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {Loading ? (
                <CircularProgress />
              ) : (
                <form className="col g-3 ">
                  <div className="col-auto">
                    Department:
                    <label className="visually-hidden">department:</label>
                    <input
                      type="text"
                      value={department}
                      onChange={(e) => setdepartment(e.target.value)}
                      className="form-control"
                      id="staticEmail2"
                      placeholder="eg: What is the capital of Nepal?"
                    />
                  </div>
                  <div className="col-auto">
                    Contact No.
                    <input
                      value={contact}
                      onChange={(e) => setcontact(e.target.value)}
                      type="text"
                      className="form-control m-1"
                      department="option1"
                      placeholder="Option 1"
                    />
                  </div>
                </form>
              )}
            </div>
            <div className="modal-footer">
              <button
                onClick={() => setShowEdit(false)}
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-primary"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditContact;
