import firebase from "firebase";
import React, { useState } from "react";

const AddBook = ({ dbName, subdbName, type }) => {
  const [name, setname] = useState("");
  const [pdfLink, setpdfLink] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    firebase
      .database()
      .ref(`/app/books/${dbName}/`)
      .push({
        name,
        pdfLink
      });
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="col g-3 ">
        <div className="col-auto">
          Name:
          <label  className="visually-hidden">
            Name:
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setname(e.target.value)}
            className="form-control"
            id="staticEmail2"
            placeholder="eg: Police"
          />
        </div>
        <div className="col-auto">
          Link:
          <input
            value={pdfLink}
            onChange={(e) => setpdfLink(e.target.value)}
            type="text"
            className="form-control m-1"
            name="option1"
          />
        </div>
        <div className="col-auto">
          <button type="submit" className="btn btn-primary mb-3">
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddBook;
