import firebase from "firebase";
import React from "react";
import { Button } from "react-bootstrap";

const Delete = (props) => {
  const deleteData = () => {
    if (window.confirm("Are you sure to delete this record?")) {
      firebase
        .database()
        .ref(props.dbUrl)
        .remove((err) => {
          if (err) console.log(err);
        });
    }
  };
  return (
    <Button
      onClick={() => {
        deleteData();
      }}
      className="btn-danger"
    >
      Delete
    </Button>
  );
};

export default Delete;
