import firebase from "firebase";
import React, { useState } from "react";

const AddItem = ({ dbName, subdbName, type }) => {
  const [Question, setQuestion] = useState("");
  const [Option1, setOption1] = useState("");
  const [Option2, setOption2] = useState("");
  const [Option3, setOption3] = useState("");
  const [Option4, setOption4] = useState("");
  const [CorrectOption, setCorrectOption] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    firebase
      .database()
      .ref(`/quiz/${dbName}/${type}/${subdbName}/Question`)
      .push({
        question: Question,
        options: [Option1, Option2, Option3, Option4],
        correct_option: CorrectOption,
      });
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="col g-3 ">
  
        <div className="col-auto">
          Question:
          <label htmlFor="staticEmail2" className="visually-hidden">
            Question:
          </label>
          <input
            type="text"
            value={Question}
            onChange={(e) => setQuestion(e.target.value)}
            className="form-control"
            id="staticEmail2"
            placeholder="eg: What is the capital of Nepal?"
          />
        </div>
        <div className="col-auto">
          Options:
          <input
            value={Option1}
            onChange={(e) => setOption1(e.target.value)}
            type="text"
            className="form-control m-1"
            name="option1"
            placeholder="Option 1"
          />
          <input
            type="text"
            value={Option2}
            onChange={(e) => setOption2(e.target.value)}
            className="form-control m-1"
            name="option2"
            placeholder="Option 2"
          />
          <input
            type="text"
            value={Option3}
            onChange={(e) => setOption3(e.target.value)}
            className="form-control m-1"
            name="option3"
            placeholder="Option 3"
          />
          <input
            type="text"
            value={Option4}
            onChange={(e) => setOption4(e.target.value)}
            className="form-control m-1"
            name="option4"
            placeholder="Option 4"
          />
        </div>
        <div className="col-auto">
          Correct Option:
          <label htmlFor="staticEmail2" className="visually-hidden">
            Correct Option:
          </label>
          <input
            value={CorrectOption}
            onChange={(e) => setCorrectOption(e.target.value)}
            type="text"
            placeholder="Copy paste correct option from above input field"
            className="form-control m-1"
          />
        </div>
        <br />
        <div className="col-auto">
          <button type="submit" className="btn btn-primary mb-3">
            Add Question
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddItem;
