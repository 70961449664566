import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const RealDashboard = ({ dbName }) => {
  return (
    <div style={{ padding: 2 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          paddingTop: 10,
        }}
      >
        <Link to={`/quiz/${dbName}`}>
          {" "}
          <Button>Quiz Setting</Button>
        </Link>
        <br />
        <Link to={`/contact/`}>
          <Button>Contact Setting</Button>
        </Link>
        <br />
        <Link to={`/books/${dbName}`}>
          <Button>Books Setting</Button>
        </Link>
        <br />
        <Link to={`/physical/${dbName}`}>
          <Button>Physical</Button>
        </Link>
        <br />
        <Link to={`/about/${dbName}`}>
          <Button>About</Button>
        </Link>
      </div>
    </div>
  );
};

export default RealDashboard;
