import { CircularProgress } from "@mui/material";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Delete from "../..//Delete";
import AddItem from "./AddItem";
import EditQuizItem from "./EditQuizItem";

const QuizCURD = () => {
  const [ShowEdit, setShowEdit] = useState(false);
  const [EditingItemURL, setEditingItemURL] = useState("");
  const { dbName, subdbName, type } = useParams();
  const [Data, setData] = useState([]);
  const [logo, setLogo] = useState("");
  const [QuestionTitle, setQuestionTitle] = useState("");
  const [Loading, setLoading] = useState(true);

  const getData = () => {
    setLoading(true);
    firebase
      .database()
      // /quiz/nsi/normal/sdsfasdasdmklasn/title
      .ref(`/quiz/${dbName}/${type}/${subdbName}`)
      .on("value", function (snapshot) {
        setData(snapshot.val());
        setLoading(false);
        setLogo(snapshot.val().logo);
        setQuestionTitle(snapshot.val().title);
      });
  };

  const updateSetDetails = () => {
    if (window.confirm("Are you sure to Update this title and logo?")) {
      firebase
        .database()
        .ref(`/quiz/${dbName}/${type}/${subdbName}/title`)
        .set(QuestionTitle);

      firebase
        .database()
        .ref(`/quiz/${dbName}/${type}/${subdbName}/logo`)
        .set(logo);
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [dbName, subdbName, type]);

  return (
    <div>
      {Loading ? (
        <CircularProgress />
      ) : (
        <>
          <h2>{Data.title}</h2>
          <div className="row ml-2">
            <div className="col-auto">
              <input
                value={QuestionTitle}
                onChange={(e) => setQuestionTitle(e.target.value)}
                type="test"
                className="form-control"
                placeholder="Update this title"
              />
            </div>
            <input
              value={logo}
              onChange={(e) => setLogo(e.target.value)}
              type="text"
              className="form-control m-1"
              name="Logo"
              placeholder="Logo link"
            />
            <div className="col-auto">
              <button
                onClick={updateSetDetails}
                type="submit"
                className="btn btn-primary mb-3"
              >
                Update
              </button>
            </div>
          </div>
          <AddItem dbName={dbName} subdbName={subdbName} type={type} />
          {ShowEdit && (
            <EditQuizItem
              setShowEdit={setShowEdit}
              EditingItemURL={EditingItemURL}
            />
          )}

          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Question set</th>
                <th scope="col">Options</th>
                <th scope="col">Correct Option</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(Data.Question).map((index, id) => {
                return (
                  <tr key={index}>
                    <th scope="row">{id + 1}</th>
                    <td>{Data.Question[index].question}</td>
                    <td>
                      <div className="hstack gap-3">
                        {Data.Question[index].options.map((data) => {
                          return <div className="bg-light border">{data}</div>;
                        })}
                      </div>
                    </td>

                    <td>{Data.Question[index].correct_option}</td>
                    <td>
                      <Button
                        onClick={async () => {
                          await setEditingItemURL(
                            `/quiz/${dbName}/${type}/${subdbName}/Question/${index}`
                          );
                          await setShowEdit(true);
                        }}
                      >
                        Edit
                      </Button>
                      &nbsp;
                      {Object.values(Data.Question).length > 2 && (
                        <Delete
                          dbUrl={`/quiz/${dbName}/${type}/${subdbName}/Question/${index}`}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default QuizCURD;
