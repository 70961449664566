import React from "react";
// import Signup from "./Signup";
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
// import ForgotPassword from "./ForgotPassword";
import UpdateProfile from "./UpdateProfile";
import Quiz from "../realDashboard/quiz/Index";
import QuizCURD from "../realDashboard/quiz/CURD";
import Contact from "../realDashboard/contact";
import Books from "../realDashboard/books";
import Physical from "../realDashboard/physical";
import About from "../realDashboard/about";
import Notification from "../realDashboard/notification/";
import dataAdder from "../dataAdder/index";

function App() {
  return (
    <div>
      <div>
        <Router>
          <AuthProvider>
            <Switch>
              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute
                exact
                path="/update-profile"
                component={UpdateProfile}
              />
              <PrivateRoute exact path="/quiz/:dbName" component={Quiz} />
              <PrivateRoute exact path="/contact/" component={Contact} />
              <PrivateRoute exact path="/books/:dbName" component={Books} />
              <PrivateRoute exact path="/physical/:dbName" component={Physical} />
              <PrivateRoute exact path="/about/:dbName" component={About} />
              <PrivateRoute exact path="/notification" component={Notification} />
              <PrivateRoute exact path="/dataAdder" component={dataAdder} />
              <PrivateRoute path="/quiz/edit/:dbName/:type/:subdbName" component={QuizCURD} />
              <Route path="/login" component={Login} />
              {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
            </Switch>
          </AuthProvider>
        </Router>
      </div>
    </div>
  );
}

export default App;
