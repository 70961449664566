import { CircularProgress } from "@mui/material";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import AddBook from "./AddBook";
import Delete from "../Delete";
import { Button } from "react-bootstrap";
import EditBooks from "./EditBooks";
const Contact = () => {
  const { dbName } = useParams();
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [ShowEdit, setShowEdit] = useState(false);
  const [EditingItemURL, setEditingItemURL] = useState("");
  const getData = () => {
    setLoading(true);
    firebase
      .database()
      .ref(`/app/books/${dbName}/`)
      .on("value", function (snapshot) {
        setData(snapshot.val());
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [dbName]);

  return (
    <div>
      {Loading ? (
        <CircularProgress />
      ) : (
        <>
          <h2>Edit books {dbName}</h2>
          <div className="row ml-2"></div>
          <AddBook dbName={dbName} />
          {ShowEdit && (
            <EditBooks
              setShowEdit={setShowEdit}
              EditingItemURL={EditingItemURL}
            />
          )}
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">pdf Link</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(Data).map((index, id) => {
                return (
                  <tr key={index}>
                    <th scope="row">{id + 1}</th>
                    <td>{Data[index].name}</td>

                    <td>
                      <a rel="noopener noreferrer" href={Data[index].pdfLink} target="_blank">
                        {Data[index].pdfLink}{" "}
                      </a>
                    </td>

                    <td>
                      <Button
                        onClick={async () => {

                          await setEditingItemURL(
                            `/app/books/${dbName}/${index}`
                          );
                          await setShowEdit(true);
                        }}
                      >
                        Edit
                      </Button>
                      &nbsp;
                      {Object.values(Data).length > 1 && (
                        <Delete dbUrl={`/app/books/${dbName}/${index}`} />
                      )
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default Contact;
