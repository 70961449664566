import firebase from "firebase";
import React, { useState } from "react";

const AddQuizset = ({ dbName, type }) => {
  const [QuestionSetTitle, setQuestionSetTitle] = useState("");
  const [logo, setLogo] = useState("");
  const [Question, setQuestion] = useState("");
  const [Option1, setOption1] = useState("");
  const [Option2, setOption2] = useState("");
  const [Option3, setOption3] = useState("");
  const [Option4, setOption4] = useState("");
  const [CorrectOption, setCorrectOption] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    firebase
      .database()
      .ref(`/quiz/${dbName}/${type}/`)
      .push({
        title: QuestionSetTitle,
        logo,
        Question: {
          [(Math.random() + 1).toString(36).substring(7)]: {
            question: Question,
            options: [Option1, Option2, Option3, Option4],
            correct_option: CorrectOption,
          },
        },
      });
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="col g-3 ">
        Question set title:
        <input
          value={QuestionSetTitle}
          onChange={(e) => setQuestionSetTitle(e.target.value)}
          type="text"
          className="form-control m-1"
          name="QuestionSetTitle"
          placeholder="Question Set Title"
        />
        <input
          value={logo}
          onChange={(e) => setLogo(e.target.value)}
          type="text"
          className="form-control m-1"
          name="QuestionSetTitle"
          placeholder="Logo link"
        />
        <p>Add at list one question</p>
        <div className="col-auto">
          Question:
          <label htmlFor="staticEmail2" className="visually-hidden">
            Question:
          </label>
          <input
            type="text"
            value={Question}
            onChange={(e) => setQuestion(e.target.value)}
            className="form-control"
            id="staticEmail2"
            placeholder="eg: What is the capital of Nepal?"
          />
        </div>
        <div className="col-auto">
          Options:
          <input
            value={Option1}
            onChange={(e) => setOption1(e.target.value)}
            type="text"
            className="form-control m-1"
            name="option1"
            placeholder="Option 1"
          />
          <input
            type="text"
            value={Option2}
            onChange={(e) => setOption2(e.target.value)}
            className="form-control m-1"
            name="option2"
            placeholder="Option 2"
          />
          <input
            type="text"
            value={Option3}
            onChange={(e) => setOption3(e.target.value)}
            className="form-control m-1"
            name="option3"
            placeholder="Option 3"
          />
          <input
            type="text"
            value={Option4}
            onChange={(e) => setOption4(e.target.value)}
            className="form-control m-1"
            name="option4"
            placeholder="Option 4"
          />
        </div>
        <div className="col-auto">
          Correct Option:
          <label htmlFor="staticEmail2" className="visually-hidden">
            Correct Option:
          </label>
          <input
            value={CorrectOption}
            onChange={(e) => setCorrectOption(e.target.value)}
            type="text"
            className="form-control"
            placeholder="Copy paste correct option from above input field"
          />
        </div>
        <br />
        <div className="col-auto">
          <button type="submit" className="btn btn-primary mb-3">
            Add Question set
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddQuizset;
